export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'forbehold',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.content,
            },
            props: {
              class: 'forbehold__text',
            },
          },
          {
            component: 'GoBack',
            props: {
              class: 'forbehold__goback',
            },
            data: {
              label: '',
            },
          },
        ],
      },
    },
  ]
}
