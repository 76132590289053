<template>
  <div class="slider" v-if="items.length">
    <Slider
      :value="items"
      :display-thumbnail="true"
      :manual-navigation="true"
      :captionPlacement="'inside'"
    />
  </div>
</template>

<script>
import Slider from '@kvass/media-render/Slider'

export default {
  props: {
    description: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
    hideStats: Boolean,
  },
  computed: {
    items() {
      return this.images
    },
  },
  methods: {},

  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.slider {
  @include respond-below('desktop') {
    height: 66vh;
  }
  @include respond-below('phone') {
    height: 33vh;
  }

  $height: 85vh;

  position: relative;
  height: $height;
  width: 100%;
  margin: 0 auto;
  background-color: css-alpha('primary', 0.1);

  .kvass-media-render-image__caption {
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: initial;
    padding-block: initial;
    backdrop-filter: initial;
    background: hsla(0, 0%, 97.6%, 0.55);
    padding: 5px 10px;
    text-align: left;
    color: black;
    font-size: 85%;
  }

  .kvass-media-render-slider__navigation-button {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: initial;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @include respond-above('tablet') {
    // height: 70vh;
  }

  .kvass-media-render-slider .kvass-media-render-image {
    // top: var(--parallax-top, 0);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
