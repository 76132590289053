import { filter, transformContent } from '@/utils'
export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'header',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: transformContent(this.content),
            },
            props: {
              class: 'standard-post-text post__header',
            },
          },

          {
            component: 'Image',

            data: {
              image: this.media.cover[0],
            },
            props: {
              class: 'header__image',
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'FlatFinder',
            props: {
              value: this.flatfinders,
            },
          },
          {
            component: 'Residentials',
            props: {
              value: this.residentials,
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        class: 'areas-section',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.article || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              class: '',
              gap: '0rem',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'standard-post-text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: '',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ],
            },
          }
        }),
      },
    },
    {
      component: 'Banner',
      props: {
        class: 'call-to-action',
      },
      data: {
        backgroundImage: this.customFields['call-to-action'].image.url,
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.customFields['call-to-action'].content,
            },
            props: {
              class: 'call-to-action__text',
            },
          },
          {
            component: 'Image',
            data: {
              image: this.customFields['call-to-action'].image,
            },
            props: {
              class: 'call-to-action__image',
            },
          },
        ],
      },
    },
  ]
}
