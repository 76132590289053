import { filter, transformContent } from '@/utils'
export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: '',
        style: '--section-padding-block: 0rem 2rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'standard-post-text post__header',
            },
            data: {
              content: transformContent(this.content),
            },
          },
          {
            component: 'Slider',
            props: {
              class: 'post__slider',
              images: this.media.cover || [],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition:
        (this.customFields.sections || [{}])[0].content ||
        (this.customFields.sections || [{}])[0].image,

      props: {
        class: 'section',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.sections || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: `section__layout ${
                index % 2 ? 'section__layout--odd' : 'section__layout--even'
              }`,
              gap: '0rem',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'section__text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'section__image',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ],
            },
          }
        }),
      },
    },
    {
      component: 'Section',
      condition:
        (this.customFields.article || [{}])[0].content ||
        (this.customFields.article || [{}])[0].image,
      props: {
        class: 'article',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.article || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: 'article__layout',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: item.content,
                  props: {
                    class: 'standard-post-text article__text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'article__image',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ].filter(f => filter(f)),
            },
          }
        }),
      },
    },

    {
      component: 'Section',
      condition:
        (this.customFields.owner || [{}])[0].content || (this.customFields.owner || [{}])[0].image,
      props: {
        class: 'owner',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.owner || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: 'owner__layout',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'owner__text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'owner__image',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ],
            },
          }
        }),
      },
    },

    {
      component: 'Banner',
      props: {
        class: 'call-to-action',
      },
      data: {
        backgroundImage: this.customFields['call-to-action'].image.url,
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.customFields['call-to-action'].content,
            },
            props: {
              class: 'call-to-action__text',
            },
          },
          {
            component: 'Image',
            data: {
              image: this.customFields['call-to-action'].image,
            },
            props: {
              class: 'call-to-action__image',
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
