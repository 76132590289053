import { filter, transformContent } from '@/utils'
export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: '',
        style: '--section-padding-block: 0rem 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'standard-post-text post__header',
            },
            data: {
              content: transformContent(this.content),
            },
          },
          {
            component: 'Slider',
            props: {
              class: 'building__header-image',
              images: this.media.cover || [],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'section section__first',
      },
      blocks: {
        default: this.customFields.section
          .map((item, index) => {
            return [
              {
                component: 'Flex',
                props: {
                  class: `section__layout ${
                    index % 2
                      ? 'section__layout--even section__layout--white'
                      : 'section__layout--odd'
                  }`,
                  gap: '0rem',
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      props: {
                        class: 'section__text',
                      },
                      data: {
                        content: item.content,
                      },
                    },
                    {
                      component: 'Image',
                      props: {
                        class: 'section__image',
                      },
                      data: {
                        image: item.image,
                      },
                    },
                  ],
                },
              },

              {
                component: 'Flex',
                props: {
                  class: 'building__features',
                },
                blocks: {
                  default: (item.icons || []).map((icon, index) => {
                    return {
                      component: 'Image',
                      props: {
                        class: `building__feature ${
                          item.icons.length % 3 == 0
                            ? 'building__feature--33'
                            : 'building__feature--25'
                        }`,
                      },
                      data: {
                        image: icon,
                      },
                    }
                  }),
                },
              },
            ]
          })
          .flat(),
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
      },
      blocks: {
        default: {
          component: 'Slider',
          props: {
            class: '',
            images: this.customFields.images || [],
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        class: '',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.article || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              class: '',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: item.content,
                  props: {
                    class: 'standard-post-text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    style: 'margin-top: 1rem',
                    class: '',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ].filter(f => filter(f)),
            },
          }
        }),
      },
    },

    {
      component: 'Section',
      props: {
        class: 'section ',
        width: 'full',
      },
      blocks: {
        default: (this.customFields.sections || []).map((item, index) => {
          return {
            component: 'Flex',
            props: {
              class: `section__layout ${
                index % 2 ? 'section__layout--odd section__layout--white' : 'section__layout--even'
              }`,
              gap: '0rem',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'section__text',
                  },
                  data: {
                    content: item.content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'section__image',
                  },
                  data: {
                    image: item.image,
                  },
                },
              ],
            },
          }
        }),
      },
    },

    {
      component: 'Banner',
      props: {
        class: 'call-to-action',
      },
      data: {
        backgroundImage: this.customFields['call-to-action'].image.url,
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.customFields['call-to-action'].content,
            },
            props: {
              class: 'call-to-action__text',
            },
          },
          {
            component: 'Image',
            data: {
              image: this.customFields['call-to-action'].image,
            },
            props: {
              class: 'call-to-action__image',
            },
          },
        ],
      },
    },
  ]
}
