<template>
  <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/subpages/about.js'
import Slider from '@/components/Slider.vue'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return { Slider }
    },
  },
  components: {
    Blocks,

    Slider,
  },
}
</script>

<style lang="scss"></style>
