<template>
  <Loader :value="promises.fetch" class="post">
    <component v-if="component" :is="component" :value="post" :key="post.id" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Types from './Types'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Post', ['promises', 'item']),

    post() {
      if (!this.item.posts) return {}
      let post = this.item.posts.find(p => p.slug === this.slug)

      // attach call to action if not defined
      if (
        !post.customFields['call-to-action'] ||
        post.customFields['call-to-action'] === '<p></p>'
      ) {
        post.customFields['call-to-action'] = this.item.customFields['call-to-action']
      }

      // attach articles if selected
      if (post.customFields['article-cards-checked']) {
        let articles = this.item.posts.filter(item => Boolean(item.customFields['is-article']))
        //sort articles
        articles.sort((a, b) => a.customFields.order - b.customFields.order)
        post.articles = articles
      }

      // attach address if selected
      if (post.customFields['show-address-map']) {
        post.address = this.item.address
      }

      // attach flatfinder && brochure && projectid && residentials
      post.flatfinders = this.item.flatfinders
      post.media.brochure = this.item.media.brochure
      post.projectId = this.item.id
      post.residentials = this.item.residentials

      return post
    },

    component() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || []
      let key = (sources[0] || '')

      switch (key) {
        case 'about':
          return Types.About
        case 'location':
          return Types.Location
        case 'area':
          return Types.Areas
        case 'storage':
          return Types.Buildings
        case 'forbehold':
          return Types.Forbehold
        default:
          return Types.Default
      }
    },
  },
  methods: {
    ...mapActions('Post', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
  },
}
</script>

<style lang="scss">
.post {
  @keyframes PageTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__header {
    @include respond-above('desktop') {
      margin-top: 4rem;
    }
  }

  .standard-post-text {
    padding: 2rem 4rem;
    font-size: 1.3rem;
    max-width: 80%;

    h1,
    h2 {
      font-size: 45px;

      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
    }
    p {
      margin: 0 0 1.5rem;
    }

    @include respond-below('phone') {
      padding: 1.5rem 2rem;
      max-width: 100%;
    }
  }

  &__slider {
  }

  .section {
    background-color: var(--beige);

    &__first {
      padding-top: 2rem;
    }

    &__no-padding {
      padding-top: 0;
    }
    &__layout {
      flex-direction: column-reverse !important;

      padding-bottom: 65px;
      padding-top: 25px;
      @include respond-above('phone') {
        padding-top: 65px;
      }

      align-items: center !important;
      &--odd {
        @include respond-above('phone') {
          flex-direction: row-reverse !important;
        }
      }
      &--even {
        @include respond-above('phone') {
          flex-direction: row !important;
        }
      }
      &--white {
        background-color: white;
      }
    }

    &__text {
      line-height: 1.7;

      font-size: 1.1rem;

      text-align: left;
      max-width: 28.125rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem 1.5rem;
      margin-bottom: 1.5rem;
      @include respond-below('phone') {
        width: 100%;
      }
      h1,
      h2 {
        font-size: 45px;
        margin-top: 1.5rem;
        margin-bottom: 0rem;
      }
      a {
      }
      ul {
        padding-inline-start: 1em;
        margin-bottom: 1.5rem;
        li::marker {
          color: var(--secondary);
          content: '- ';
        }
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
    &__image {
      @include respond-above('phone') {
        width: 50%;

        min-height: 595px;
      }
    }
  }

  .article {
    --section-padding-block: clamp(2rem, 6vw, 4rem);
    &__layout {
      gap: 0 !important;
      @include respond-below('phone') {
        flex-direction: column-reverse !important;
      }
    }
    &__text {
      max-width: 28.125rem;
      margin: 0 auto;
      line-height: 1.7;
      padding: 0;
      padding: 1.5rem 1rem;
      width: 50%;
      font-size: 1.1rem;
      h1,
      h2 {
        margin-top: 0;
      }
      @include respond-below('phone') {
        width: 100%;
      }
    }
    &__image {
      width: 50%;
      .kpb-image__image {
        object-fit: contain;
        background-color: inherit;
      }
      @include respond-below('phone') {
        width: 100%;
      }
    }
  }

  .owner {
    background-color: var(--beige);
    color: rgb(0, 0, 0);
    &__layout {
      max-width: 28.125rem;
      margin: 0 auto;
      flex-direction: column !important;

      @include respond-above('phone') {
        max-width: 45rem;
      }
      @include respond-above('tablet') {
        max-width: 60rem;
      }
      @include respond-above('desktop') {
        max-width: 71.25rem;
      }
    }
    &__text {
      line-height: 1.7;
      padding: 1rem 1.5rem;
      margin: 1.5rem 0;
      font-size: 1.1rem;
      h1,
      h2 {
        font-size: 45px;
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;
      }
    }
    &__image {
      max-width: 28.125rem;
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 6rem;
      .kpb-image__image {
        object-fit: contain;
        background-color: inherit;
      }
      @include respond-below('phone') {
        width: 50%;
      }
    }
  }

  .location {
    gap: 0 !important;
    @include respond-below('phone') {
      // CONTENT ORDER ON MOBILE
      &__header-text {
        order: 1;
      }
      &__header-gallery {
        .masonry__content {
          flex-direction: column-reverse !important;
        }
        order: 6;
      }
      &__transport-text {
        order: 2;
      }
      &__transport-image {
        order: 4;
      }
      &__transport-features {
        order: 5;
      }
      &__transport-cta {
        order: 7;
      }
      &__section-text {
        order: 3;
      }
      &__section-image {
        order: 8;
      }
      &__call-to-action {
        order: 9;
      }
    }

    &__transport-image {
      min-height: 350px;
    }

    &__header-gallery {
      padding: 0;

      .kpb-gallery__item:not(:first-of-type) {
        @include respond-below('phone') {
          width: 90%;
        }

        margin: 65px auto;
        width: 75%;
      }
    }

    &__header-text {
      @extend .standard-post-text;
    }
    &__transport-text,
    &__section-text {
      @extend .standard-post-text;
      h1,
      h2 {
        font-size: 1.4rem;

        @media (min-width: 544px) {
          font-size: 1.6rem;
        }
        @include respond-above('phone') {
          font-size: 2rem;
        }
        @include respond-below('phone') {
          margin-top: 0;
        }
      }
    }

    &__transport-features {
      background-color: var(--beige);
    }
    &__transport-cta {
      background-color: var(--beige);
      text-align: center;
      a {
        &:after {
          display: none;
        }
      }
      padding: 2rem 1.5rem;
    }
    &__section-image {
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .building {
    &__header-image {
      max-height: 600px;

      min-height: 350px;
    }

    &__features {
      max-width: 1200px;
      margin: auto;

      justify-content: space-around !important;
      flex-wrap: wrap !important;
      align-items: center !important;
      gap: 3rem !important;
      padding-bottom: 3rem;
      padding-top: 1rem;
      @include respond-above('phone') {
        gap: 0 !important;
        row-gap: 4rem !important;
      }
    }
    &__feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0rem;

      width: 100%;
      flex-grow: 1;

      &--33 {
        @include respond-above('phone') {
          width: 33%;
        }
      }

      &--25 {
        @include respond-above('phone') {
          width: 25%;
        }
      }

      .kpb-image__image {
        margin: 1em;
        width: 100px;
        height: 100px;
        background-color: inherit;
      }
      .kpb-image__caption {
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;

        font-weight: 400;
      }
    }
  }

  .features {
    max-width: 1200px;
    margin: auto;
    justify-content: space-around !important;
    flex-wrap: wrap !important;
    align-items: center !important;

    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include respond-below('phone') {
      padding-top: 3rem;
      padding-bottom: 3rem;
      flex-direction: column !important;
      gap: 5rem !important;
    }

    &__feature {
      width: 100%;
      @include respond-above('phone') {
        width: 20%;
      }
    }
    &__icon {
      width: 100px;
      height: 100px;

      .kpb-image__image {
        background-color: unset;
      }
    }
    &__title {
      margin: 0 0 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;

      font-size: 1rem;
    }
    &__subtitle {
      font-size: 1rem;
      word-break: break-word;
    }
  }

  .call-to-action {
    background-color: var(--secondary);
    color: white;
    background-size: contain;

    background-position: right center;

    @include respond-below('phone') {
      background-image: none !important;
    }

    &__text {
      padding: 1rem 2.5rem;
      @include respond-above('phone') {
        margin-left: 5%;
      }

      @include respond-above('desktop') {
        padding-top: 2rem;
        padding-bottom: 3rem;
        margin-left: 10%;
      }
      h1,
      h2 {
        max-width: 35rem;

        margin-top: 1.5rem;
        margin-bottom: 0.75rem;

        font-size: 1.4rem;
        @media (min-width: 544px) {
          font-size: 1.6rem;
        }
        @include respond-above('phone') {
          font-size: 45px;
        }
      }

      p {
        max-width: 30rem;
        a[data-cta='primary'] {
          padding: 0.6rem;
          font-weight: 400;
          margin-top: 1rem;
        }
      }
    }
    &__image {
      @include respond-above('phone') {
        display: none;
      }

      width: 100%;
      height: auto;
      min-height: 180px;

      .kpb-image__image {
        object-fit: cover;
        object-position: right top;
        background-color: inherit;
      }
    }
  }

  .areas-section {
    margin-bottom: 25px;
  }
}
</style>
