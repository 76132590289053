<template>
  <div class="go-back">
    <a @click="$router.go(-1)" :class="`go-back__link go-back__link-${variant}`"
      ><FontAwesomeIcon icon="arrow-left" class="go-back__link-icon" />
      {{ data.label || $t('goBack') }}</a
    >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      label: String,
    },
    variant: {
      type: String,
      default: 'white',
    },
  },
}
</script>

<style lang="scss">
.go-back {
  text-align: center;
  &__link {
    text-decoration: none;
    display: inline-block;

    min-width: 200px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
    font-size: 1.1rem;

    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &-icon {
      margin-right: 10px;
    }

    &-white {
      background-color: rgb(255, 255, 255);
      color: rgb(51, 51, 51);
      &:hover {
        background-color: rgb(245, 245, 245);
      }
    }

    &-black {
      color: rgb(255, 255, 255);
      background-color: rgb(0, 0, 0);
      &:hover {
        background-color: rgb(26, 26, 26);
      }
    }
  }
}
</style>
