<template>
  <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import Masonry from '@kvass/template-gallery/Types/Masonry'
import config from '@/config/subpages/location.js'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return { Masonry }
    },
  },
  components: {
    Blocks,
    Masonry,
  },
}
</script>

<style lang="scss"></style>
