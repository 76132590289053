import { filter, transformContent } from '@/utils'

export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              class: 'location',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'location__header-text',
                  },
                  data: {
                    content: transformContent(this.content),
                  },
                },
                {
                  component: 'Gallery',
                  data: {
                    images: this.media.cover || [],
                  },
                  props: {
                    gap: '0.5rem',
                    columns: 1,
                    class: 'location__header-gallery',
                  },
                },
                {
                  component: 'Text',
                  props: {
                    class: 'location__transport-text',
                  },
                  data: {
                    content: this.customFields['transport-section'].content,
                  },
                },
                {
                  component: 'Image',
                  props: {
                    class: 'location__transport-image',
                  },
                  data: {
                    image: this.customFields['transport-section'].image,
                  },
                },
                {
                  component: 'Section',
                  props: {
                    class: 'location__transport-features',
                    width: 'full',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Flex',
                        props: {
                          class: 'features',
                          gap: '0rem',
                        },
                        blocks: {
                          default: this.customFields.features.map((item, index) => {
                            return {
                              component: 'Flex',
                              props: {
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '0rem',
                                class: 'features__feature',
                              },
                              blocks: {
                                default: [
                                  {
                                    component: 'Image',
                                    props: {
                                      class: 'features__icon',
                                    },
                                    data: {
                                      image: item.icon,
                                    },
                                  },
                                  {
                                    component: 'Text',
                                    props: {
                                      textAlign: 'center',
                                      class: 'features__title',
                                    },
                                    data: {
                                      content: item.title,
                                    },
                                  },
                                  {
                                    component: 'Text',
                                    props: {
                                      textAlign: 'center',
                                      class: 'features__subtitle',
                                    },
                                    data: {
                                      content: item.subtitle,
                                    },
                                  },
                                ],
                              },
                            }
                          }),
                        },
                      },
                    ],
                  },
                },
                {
                  component: 'Text',
                  props: {
                    class: 'location__transport-cta',
                  },
                  data: {
                    content: this.customFields['features-call-to-action'],
                  },
                },
                ...this.customFields.sections
                  .map((item, index) => {
                    return [
                      {
                        component: 'Text',
                        props: {
                          class: 'location__section-text',
                        },
                        data: {
                          content: item.content,
                        },
                      },
                      {
                        component: 'Image',
                        props: {
                          class: 'location__section-image',
                        },
                        data: {
                          image: item.image,
                        },
                      },
                    ]
                  })
                  .flat(),
                {
                  component: 'Banner',
                  props: {
                    class: 'call-to-action location__call-to-action',
                  },
                  data: {
                    backgroundImage: this.customFields['call-to-action'].image.url,
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Text',
                        data: {
                          content: this.customFields['call-to-action'].content,
                        },
                        props: {
                          class: 'call-to-action__text',
                        },
                      },
                      {
                        component: 'Image',
                        data: {
                          image: this.customFields['call-to-action'].image,
                        },
                        props: {
                          class: 'call-to-action__image',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
