<template>
  <div>
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </div>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import Hero from '@/components/Hero'
import config from '@/config/subpages/areas.js'
import Residentials from '@/components/Residentials'
import FlatFinder from '@/components/Flatfinder'
import Lead from '@kvass/lead'
import GoBack from '@/components/GoBack'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return { Hero, Residentials, FlatFinder, Lead, GoBack }
    },
  },
  components: {
    Hero,
    Residentials,
    FlatFinder,
    Lead,
    Blocks,
    GoBack,
  },
}
</script>

<style lang="scss"></style>
