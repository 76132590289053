<template>
  <Flatfinder
    class="residential-flatfinder"
    :value="value"
    :properties="properties"
    :preventNavigation="false"
  />
</template>

<script>
import Flatfinder from '@kvass/flatfinder'

export default {
  props: {
    value: Array,
  },

  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  components: {
    Flatfinder,
  },
}
</script>
<style lang="scss">
.residential-flatfinder {
  .flatfinder__title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    word-break: break-word;
    text-align: left;
    padding: 0 2vh;
  }
  .flatfinder__filter-wrapper {
    min-height: unset;
  }
}
</style>
