<template>
  <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/consent/forbehold.js'
import GoBack from '@/components/GoBack'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return { GoBack }
    },
  },
  components: {
    Blocks,
    GoBack,
  },
}
</script>

<style lang="scss">
.forbehold {
  --section-padding-block: 6rem 0rem;

  &__text {
    max-width: 37.5rem;
    margin: 0 auto;

    li::marker {
      color: initial;
    }
    h2 {
      font-size: 2.3rem;
      font-weight: 400;
    }

    @include respond-below('tablet') {
      h2 {
        font-size: 1.84rem;
      }
    }

    @include respond-below('phone') {
      h2 {
        font-size: 1.61rem;
      }
    }
  }
  &__goback {
    padding-top: 5rem;
    padding-bottom: 5rem;
    a {
      color: inherit;
      background-color: var(--beige);
      &:hover {
        background-color: var(--beige-hover);
      }
    }
  }
}
</style>
